import Vue from 'vue';
import VueRouter from 'vue-router';

import Resources from '@/views/resources/Resources';
import DocumentsLinks from '@/views/documents-links/DocumentsLinks';
import Status from '@/views/status/Status';
import SupportFeedback from '@/views/support-feedback/SupportFeedback';
import Tasks from '@/views/tasks/Tasks';
import StaffDetails from '@/views/staff-details/StaffDetails';
import StayConnected from '@/views/stay-connected/StayConnected';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    component: Status,
  },
  {
    path: '/full-details',
    name: 'StaffDetails',
    component: StaffDetails,
  },
  {
    path: '/tasks',
    component: Tasks,
  },
  {
    path: '/tasks/:tabIndex/:phaseIndex/:rowIndex',
    component: Tasks,
  },
  {
    path: '/resources',
    component: Resources,
  },
  {
    path: '/documents-and-links',
    component: DocumentsLinks,
  },
  {
    path: '/stay-connected',
    component: StayConnected,
  },
  {
    path: '/support-feedback',
    name: SupportFeedback,
    component: SupportFeedback,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
