// Used to track both DRAWER notifications and BANNER notifications
export const gtmDeleteNotification = (gtm, notification) => {
  gtm.trackEvent({
    event: 'notification_dismiss',
    message_id: notification.id,
    message_time: notification.time,
    message_channel: notification?.displayMethod
      ? notification.displayMethod
      : 'qplus_drawer',
    message_request_id: notification.requestId,
  });
};

// When user clicks on BELL icon, Notification details are sent to GA
export const gtmClickOnNotificationDrawer = (gtm, notifications) => {
  gtm.trackEvent({
    event: 'notification_open',
    message_channel: 'qplus_drawer',
    value: {
      total: notifications.length,
    },
  });
};
