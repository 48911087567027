<template>
  <div
    v-if="feedbackSurveys"
    class="container-wrapper"
  >
    <v-container
      class="container-main py-6"
      fluid
    >
      <div>
        <h2 class="title font-weight-bold mb-4 primary--text">
          {{ feedbackSurveys.title }}
        </h2>

        <p v-if="feedbackSurveys.description">
          {{ feedbackSurveys.description }}
        </p>

        <v-row class="mb-6">
          <v-col
            v-for="item in feedbackSurveys.items"
            :key="item.title"
            md="6"
            sm="12"
          >
            <v-card
              class="fill-height"
              @click="handleOpenSurveyFeedbackDialogue(item)"
            >
              <div class="d-flex pa-5">
                <div class="d-none d-md-block">
                  <v-img
                    width="96px"
                    height="96px"
                    alt="icon"
                    contain
                    :src="thumbnails[item.image]"
                  />
                </div>
                <div class="ml-5">
                  <h3 class="title font-weight-bold primary--text">
                    {{ item.title }}
                  </h3>
                  <div
                    class="body-1 primary--text"
                    v-if="item.description"
                    v-html="item.description"
                  />
                </div>
              </div>
              <FeedbackSurveyDialog
                :openDialog="openSurveyFeedbackDialogue"
                :returnHandler="closeSurveyFeedbackDialogue"
                :item="selectedItem"
              />
            </v-card>
          </v-col>
        </v-row>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import FeedbackSurveyDialog from './FeedbackSurveyDialog.vue';

export default {
  name: 'FeedbackLinks',
  components: {
    FeedbackSurveyDialog,
  },
  data() {
    return {
      thumbnails: {},
      openSurveyFeedbackDialogue: false,
      selectedItem: null,
    };
  },
  computed: {
    ...mapState('moduleStaticContent', {
      feedbackSurveys: (state) => state.content.feedback_surveys,
    }),
  },
  watch: {
    feedbackSurveys: {
      immediate: true,
      handler(feedbackSurveys) {
        feedbackSurveys?.items?.forEach((item) => {
          if (item.image) {
            this.$set(this.thumbnails, item.image, '');
          }
        });
        Object.keys(this.thumbnails).forEach((thumbnailName) => {
          this.generateThumbnail(thumbnailName);
        });
      },
    },
  },
  created() {
    this.$store.dispatch(
      'moduleStaticContent/fetchStaticContent',
      'feedback_surveys'
    );
  },
  methods: {
    handleOpenSurveyFeedbackDialogue(item) {
      this.openSurveyFeedbackDialogue = true;
      this.selectedItem = item;
    },
    async generateThumbnail(fileName) {
      await this.$http
        .get('/api/static/resources/thumbnail/' + fileName, {
          responseType: 'blob',
        })
        .then((response) => {
          this.$set(
            this.thumbnails,
            fileName,
            URL.createObjectURL(response.data)
          );
        });
    },
    actionHandler() {},
    closeSurveyFeedbackDialogue() {
      this.openSurveyFeedbackDialogue = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.container-wrapper,
.container-main {
  background-color: $ads-light-10;
}
</style>
