/**
 * https://ssr.vuejs.org/guide/head.html
 * @param vm
 * @returns {*}
 */
function getTitle(vm) {
  // components can simply provide a `title` option
  // which can be either a string or a function
  const { title } = vm.$options;
  if (title) {
    return typeof title === 'function' ? title.call(vm) : title;
  }
}

const titleMixin = {
  created: function () {
    const title = getTitle(this);
    if (getTitle(this)) {
      window.document.title = title;
    }
  },
};

export default titleMixin;
