const moduleStaticContent = {
  namespaced: true,
  state: {
    content: {},
  },
  getters: {},
  mutations: {
    setStaticContent(state, { key, data }) {
      state.content = {
        ...state.content,
        [key]: data,
      };
    },
  },
  actions: {
    fetchStaticContent({ commit, state }, key) {
      if (!state.content[key]) {
        this._vm.$http
          .get(`/api/static/page/${key}.json`)
          .then((response) => {
            const data = response.data;

            commit('setStaticContent', { key, data });
          })
          .catch(() => {
            commit('SET_HAS_ERROR', true, { root: true });
          });
      }
    },
  },
};

export default moduleStaticContent;
