<!-- eslint-disable vue/no-template-shadow -->
<template>
  <div class="light10 fill-height">
    <Banner
      icon="playlist_add_check"
      contentClass="ml-2"
      class="display-banner"
      heading="Workspace"
      subtitle="Step by step guide through performance and accreditation"
      backgroundImageUrl="images/page-banner.png"
    />
    <div>
      <TabNavigation
        v-if="!loading"
        :grow="$vuetify.breakpoint.lgAndDown"
        centered
        mobile-breakpoint="xs"
        :value="selectedTab"
        :items="tabs"
        slider-color="#D7153A"
        slider-size="4"
      >
        <template
          v-for="(tab, iTab) in tabs"
          v-slot:[`content${iTab+1}`]
        >
          <div
            :key="iTab"
            class="light10 py-8 px-md-8"
          >
            <div class="container-main mx-auto">
              <TaskChecklist :indexCurrentChecklist="iTab" />
            </div>
          </div>
        </template>
      </TabNavigation>
    </div>
  </div>
</template>
<script>
import { Banner, TabNavigation } from '@nswdoe/doe-ui-core';
import { mapState, mapGetters } from 'vuex';
import TaskChecklist from './TaskChecklist';
import _ from 'lodash';

export default {
  title: 'Workspace - Q+',
  components: {
    Banner,
    TabNavigation,
    TaskChecklist,
  },
  data() {
    return {
      tab: null,
      loading: true,
    };
  },
  computed: {
    ...mapState('moduleStaticContent', {
      checklists: (state) => state.content.tasks,
    }),
    ...mapGetters({
      isMaintainingAccreditation:
        'moduleUserProfile/isMaintainingAccreditation',
    }),
    selectedTab() {
      if (this.isMaintainingAccreditation) return 1;
      else return 0;
    },
    tabs() {
      const checklists = this.checklists || [];

      _.forEach(checklists, (checklist) => {
        checklist.tab = checklist.name;
      });
      return checklists;
    },
  },
  created() {
    this.$store.commit('SET_IS_LOADING', true);
    this.loading = true;
    Promise.all([
      this.$store.dispatch('moduleStaticContent/fetchStaticContent', 'tasks'),
      this.$store.dispatch('moduleUserProfile/fetchUserProfile'),
      this.$store.dispatch('moduleTasks/fetchCompletedTasks'),
    ]).finally(() => {
      this.$store.commit('SET_IS_LOADING', false);
      this.loading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.display-banner {
  background-position: right 20%;
  font-size: 34px;
}

::v-deep {
  .v-tabs-bar--is-mobile {
    .v-tab {
      text-align: center;
      max-width: 184px !important;
      padding: 5px 2px;
    }
  }
}
</style>
