<template>
  <div class="container-wrapper">
    <v-container
      class=""
      v-if="staffrooms"
    >
      <v-card class="py-4 px-8">
        <div
          class="pt-5 body-1"
          v-html="description_1"
        />
        <div
          class="pt-5 body-1"
          v-html="description_2"
        />
        <div
          class="pb-8 pt-5 body-1"
          v-html="description_3"
        />

        <ads-expansion-panel :items="staffrooms.items">
          <template
            v-for="item in staffrooms.items"
            :slot="`content${item.id}`"
          >
            <div
              class="content-link"
              :key="link.text"
              v-for="link in item.content"
            >
              <a
                :href="link.url"
                v-html="link.text"
              />
            </div>
          </template>
        </ads-expansion-panel>
      </v-card>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { AdsExpansionPanel } from '@nswdoe/doe-ui-core';

export default {
  name: 'StaffroomLinks',
  components: { AdsExpansionPanel },
  computed: {
    ...mapState('moduleStaticContent', {
      staffrooms: (state) => state.content.staffrooms,
    }),
    description_1() {
      return this.staffrooms.description.line_1.replace(
        'Microsoft Teams',
        '<span class="font-weight-bold">Microsoft Teams</span>'
      );
    },
    description_2() {
      return this.staffrooms.description.line_2;
    },
    description_3() {
      return this.staffrooms.description.line_3.replace(
        'Statewide Staffrooms webpage',
        '<a class="font-weight-bold" href="https://education.nsw.gov.au/teaching-and-learning/curriculum/statewide-staffrooms">Statewide Staffrooms webpage</a>'
      );
    },
  },
  created() {
    this.$store.dispatch(
      'moduleStaticContent/fetchStaticContent',
      'staffrooms'
    );
  },
};
</script>

<style lang="scss" scoped>
.container-wrapper {
  background-color: $ads-light-10;
  height: 100vh;
}

::v-deep {
  .container {
    height: 100%;
  }
  .v-expansion-panels {
    .v-expansion-panel {
      color: $ads-primary-blue;
      margin-bottom: 24px;
    }

    .v-expansion-panel-content {
      padding: 16px 20px;
    }

    .v-expansion-panel-header {
      span {
        font-size: 16px;
        font-weight: bold;
        line-height: 24px;
      }
    }

    .panel {
      padding: 16px 20px;
    }

    .content-link {
      padding: 8px;
    }
  }
}
</style>

<!-- <v-list>
          <v-list-item v-for="item in staffrooms.items" :key="item.id">
            <div>
              <a
                class="subtitle-1 font-weight-bold"
                target="_blank"
                :href="item.url"
              >
                {{ item.text }}
              </a>
            </div>
          </v-list-item>
        </v-list> -->
