<template>
  <div class="light10 fill-height">
    <Banner
      icon="apps"
      contentClass="ml-2"
      heading="My Documents and Links"
      subtitle="Store, upload and access your essential links and documents"
      backgroundImageUrl="images/page-banner.png"
    />
    <v-container
      class="container-main py-6"
      fluid
    >
      <!-- My Documents -->
      <v-card class="mb-8">
        <v-card-title class="py-4 px-6 d-flex justify-space-between">
          <div class="d-flex align-center">
            <v-icon
              aria-hidden="true"
              color="accent"
              class="material-icons-outlined"
              left
            >
              file_copy
            </v-icon>
            <h2 class="title font-weight-bold">
              My Documents
            </h2>
          </div>

          <AdsButton
            :buttonText="
              $vuetify.breakpoint.mobile ? 'Upload' : 'Upload documents'
            "
            large
            icon="upload"
            @click="showUploadForm"
          />
          <input
            ref="uploader"
            class="d-none"
            type="file"
            accept=".ppt,.pptx,.doc,.docx,.pdf,.xls,.xlsx,.jpeg,.jpg,.png,.rtf,.bmp"
            @change="uploadDocument"
          >
        </v-card-title>

        <v-divider />

        <v-card-text class="px-6 pt-6 pb-8 dark70--text text-body-1">
          <div class="mb-6">
            Store your documents and certificates here. Upload and download your
            documents and files when you need quick access.
          </div>

          <AdsTextField
            v-show="documents && documents.length > 1"
            solo
            clearable
            label="Search my documents"
            prepend-inner-icon="mdi-magnify dark70--text"
            v-model="searchDocument"
          />

          <v-sheet
            v-for="(document, iDocument) in filteredDocuments"
            :key="iDocument"
            class="px-3 px-md-6 py-2 mb-4 light10 dark70--text d-flex justify-space-between"
            style="border-radius: 5px"
          >
            <div class="d-flex align-center">
              <v-icon
                aria-hidden="true"
                color="primary"
                class="material-icons-outlined"
                left
              >
                attach_file
              </v-icon>
              {{ document.name }}
            </div>

            <v-btn-toggle
              tile
              group
              class="font-bold"
            >
              <v-btn
                class="primary--text"
                @click="downloadDocument(document.name)"
              >
                <v-icon
                  aria-hidden="true"
                  class="material-icons-outlined mr-md-2"
                >
                  download
                </v-icon>
                <div class="d-none d-md-block">
                  Download
                </div>
              </v-btn>

              <v-btn
                class="error--text"
                @click="showDeleteDocument(document.name)"
              >
                <v-icon
                  aria-hidden="true"
                  class="material-icons-outlined mr-md-2"
                >
                  delete
                </v-icon>
                <div class="d-none d-md-block">
                  Delete
                </div>
              </v-btn>
            </v-btn-toggle>
          </v-sheet>
        </v-card-text>
      </v-card>

      <!-- My Links -->
      <v-card class="mb-8">
        <v-card-title class="py-4 px-6 d-flex justify-space-between">
          <div class="d-flex align-center">
            <v-icon
              aria-hidden="true"
              color="accent"
              class="material-icons-outlined"
              left
            >
              link
            </v-icon>
            <h2 class="title font-weight-bold">
              My Links
            </h2>
          </div>

          <div class="mx-2">
            <v-btn
              icon
              color="primary"
              @click="showAddLink"
              aria-label="Add link"
            >
              <v-icon size="26">
                mdi-plus
              </v-icon>
            </v-btn>
            <v-btn
              v-if="links && links.length"
              icon
              aria-label="Toggle edit mode"
              color="primary"
              @click="editModeLinks = !editModeLinks"
            >
              <v-icon size="26">
                mdi-pencil
              </v-icon>
            </v-btn>
          </div>
        </v-card-title>

        <div
          v-for="link in links"
          :key="link.id"
        >
          <v-divider />

          <v-card-text class="pa-6 text-body-1 d-flex justify-space-between">
            <div>
              <v-icon
                aria-hidden="true"
                class="mr-1"
              >
                mdi-open-in-new
              </v-icon>
              <a
                class="primary--text"
                :href="sanitizeUrl(link.url)"
                target="_blank"
              >{{ link.title }}</a>
            </div>
            <div v-show="editModeLinks">
              <v-icon
                aria-label="Edit link"
                class="material-icons-outlined mr-2"
                color="primary"
                @click="showEditLink(link)"
              >
                mdi-pencil
              </v-icon>
              <v-icon
                aria-label="delete link"
                class="material-icons-outlined mr-2"
                color="error"
                @click="showDeleteLink(link)"
              >
                delete
              </v-icon>
            </div>
          </v-card-text>
        </div>
      </v-card>
    </v-container>
    <Dialog
      :openDialog="openDialogDeleteDocument"
      :title="`Are you sure you want to delete &quot;${currentDocument}&quot;?`"
      :displayCloseBtn="true"
      :actions="actionsDialogDeleteDocument"
      :return-handler="deleteDocument"
      @close="openDialogDeleteDocument = false"
    />

    <Dialog
      :title="currentLink ? 'Edit link' : 'Add link'"
      :openDialog="openDialogFormLink"
      :actions="actionsDialogFormLink"
      @close="openDialogFormLink = false"
    >
      <template #title>
        <div class="px-6 py-4">
          <h2 class="primary--text title">
            {{ currentLink ? 'Edit link' : 'Add link' }}
          </h2>
        </div>
      </template>
      <template #text>
        <v-form
          ref="linkForm"
          class="px-6"
        >
          <AdsTextField
            outlined
            background-color="white"
            label="Name"
            :rules="isEmptyRules"
            v-model="formLink.title"
          />
          <AdsTextField
            outlined
            background-color="white"
            label="URL"
            :rules="isEmptyRules"
            v-model="formLink.url"
          />
        </v-form>
      </template>
      <template #actions>
        <div class="px-6 pb-6 d-flex justify-space-between">
          <v-spacer />
          <v-btn
            color="primary"
            class="mr-2"
            large
            outlined
            @click="openDialogFormLink = false"
          >
            Cancel
          </v-btn>
          <v-btn
            v-if="!currentLink"
            color="primary"
            large
            @click="addLink"
          >
            Add
          </v-btn>
          <v-btn
            v-if="currentLink"
            color="primary"
            large
            @click="editLink"
          >
            Save
          </v-btn>
        </div>
      </template>
    </Dialog>

    <Dialog
      icon="delete"
      :openDialog="openDialogDeleteLink"
      :title="`Are you sure you want to delete &quot;${
        currentLink ? currentLink.title : ''
      }&quot;?`"
      :displayCloseBtn="true"
      :actions="actionsDialogDeleteDocument"
      :return-handler="deleteLink"
      @close="openDialogDeleteLink = false"
    />

    <Alert
      type="error"
      text="Upload failed"
      subtext="File size must be under 50mb"
      v-model="maxSizeExceed"
      allowDismiss
      @dismiss="maxSizeExceed = false"
    />
  </div>
</template>

<script>
import {
  Banner,
  AdsTextField,
  AdsButton,
  Dialog,
  Alert,
} from '@nswdoe/doe-ui-core';
import { mapState } from 'vuex';
import { sanitizeUrl } from '@braintree/sanitize-url';

const formatUrl = (url) => {
  if (url) {
    let formattedUrl = url.trim();
    if (!formattedUrl.startsWith('http') && !formattedUrl.startsWith('//')) {
      formattedUrl = 'https://' + formattedUrl;
    }
    return formattedUrl;
  } else {
    return null;
  }
};

export default {
  title: 'Documents and Links - Q+',
  components: {
    Banner,
    AdsTextField,
    AdsButton,
    Dialog,
    Alert,
  },
  data() {
    return {
      searchDocument: '',
      currentDocument: undefined,
      currentLink: undefined,
      isSelectingFile: false,
      openDialogDeleteDocument: false,
      openDialogFormLink: false,
      openDialogDeleteLink: false,
      selectedFile: undefined,
      maxSizeExceed: false,
      isEmptyRules: [(v) => !!v || `This field is required.`],
      actionsDialogDeleteDocument: [
        { name: 'Cancel', color: 'primary', size: 'large', outlined: true },
        { name: 'Delete', color: 'error', size: 'large', outlined: false },
      ],
      formLink: {
        title: '',
        url: '',
      },
      actionsDialogFormLink: [
        { name: 'Cancel', color: 'primary', size: 'large', outlined: true },
        { name: 'Save', color: 'primary', size: 'large', outlined: false },
      ],
      editModeLinks: false,
      actionsDialogDeleteLink: [
        { name: 'Cancel', color: 'primary', size: 'large', outlined: true },
        { name: 'Delete', color: 'error', size: 'large', outlined: false },
      ],
    };
  },
  computed: {
    ...mapState('moduleDocuments', {
      documents: (state) => state.documents,
      links: (state) => state.links,
      electives: (state) => state.electives,
    }),
    filteredDocuments() {
      if (this.documents && this.documents.length) {
        return this.documents.filter((document) =>
          document.name.includes(this.searchDocument)
        );
      }

      return [];
    },
  },
  created() {
    const promises = [];
    if (!this.$store.state.moduleDocuments.documents) {
      promises.push(this.$store.dispatch('moduleDocuments/fetchDocuments'));
    }
    if (!this.$store.state.moduleDocuments.links) {
      promises.push(this.$store.dispatch('moduleDocuments/fetchLinks'));
    }
    if (!this.$store.state.moduleDocuments.electives) {
      promises.push(this.$store.dispatch('moduleDocuments/fetchElectives'));
    }
    this.$store.commit('SET_IS_LOADING', true);
    Promise.all(promises).finally(() => {
      this.$store.commit('SET_IS_LOADING', false);
    });
  },
  methods: {
    sanitizeUrl(url) {
      return sanitizeUrl(url);
    },
    showDeleteDocument(document) {
      this.currentDocument = document;
      this.openDialogDeleteDocument = true;
    },
    showDeleteLink(link) {
      this.currentLink = link;
      this.openDialogDeleteLink = true;
    },
    showEditLink(link) {
      this.currentLink = link;
      this.formLink.id = link.id;
      this.formLink.title = link.title;
      this.formLink.url = link.url;

      this.openDialogFormLink = true;
    },
    showAddLink() {
      this.currentLink = undefined;
      this.formLink.title = '';
      this.formLink.url = '';
      this.openDialogFormLink = true;
      this.$refs.linkForm.reset();
    },
    async deleteDocument(response = 'Delete') {
      if (response === 'Delete') {
        this.$store.commit('SET_IS_LOADING', true);

        await this.$http
          .delete('/api/user/documents/' + this.currentDocument)
          .then((response) => {
            if (response.data.success === 'true') {
              this.$store.dispatch('moduleDocuments/fetchDocuments');
            }
          })
          .catch(() => {
            this.$store.commit('SET_HAS_ERROR', true);
          })
          .finally(() => {
            if (this.$store.state.isLoading) {
              this.$store.commit('SET_IS_LOADING', false);
            }
          });
      }

      this.openDialogDeleteDocument = false;
    },
    async showDocument() {
      this.$store.commit('SET_IS_LOADING', true);

      return await this.$http
        .get('/api/user/documents/' + this.currentDocument, {
          responseType: 'blob',
        })
        .then((response) => {
          return response.data;
        })
        .catch(() => {
          this.$store.commit('SET_HAS_ERROR', true);
        })
        .finally(() => {
          if (this.$store.state.isLoading) {
            this.$store.commit('SET_IS_LOADING', false);
          }
        });
    },
    async downloadDocument(doc) {
      this.currentDocument = doc;
      const detailDocument = await this.showDocument();
      const href = URL.createObjectURL(detailDocument);
      // create "a" HTLM element with href to file & click
      const link = document.createElement('a');

      link.href = href;
      link.setAttribute('download', doc);
      document.body.appendChild(link);
      link.click();

      // clean up "a" element & remove ObjectURL
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
    },
    showUploadForm() {
      this.$refs.uploader.click();
    },
    uploadDocument(event = false) {
      if (event) {
        this.currentDocument = event.target.files[0];
        if (this.currentDocument.size / 1024 / 1024 < 50) {
          this.maxSizeExceed = false;
          const reader = new FileReader();

          reader.readAsBinaryString(this.currentDocument);

          reader.onload = async () => {
            this.$store.commit('SET_IS_LOADING', true);

            this.$http
              .put('/api/user/documents', {
                name: this.currentDocument.name,
                content_type: this.currentDocument.type,
                content: window.btoa(reader.result),
              })
              .then((response) => {
                if (response.data.success) {
                  this.$store.dispatch('moduleDocuments/fetchDocuments');
                }
              })
              .catch(() => {
                this.$store.commit('SET_HAS_ERROR', true);
              })
              .finally(() => {
                if (this.$store.state.isLoading) {
                  this.$store.commit('SET_IS_LOADING', false);
                }
              });
          };

          reader.onerror = function () {
            // TODO message box
          };
        } else {
          this.maxSizeExceed = true;
          this.$refs.uploader.value = null;
        }
      }
    },
    async addLink() {
      if (this.$refs.linkForm.validate()) {
        this.formLink.url = formatUrl(this.formLink.url);

        this.$store.commit('SET_IS_LOADING', true);

        await this.$http
          .post('/api/user/links', this.formLink)
          .then((response) => {
            if (response.data.success) {
              this.$store.dispatch('moduleDocuments/fetchLinks');
            }
          })
          .catch(() => {
            this.$store.commit('SET_HAS_ERROR', true);
          })
          .finally(() => {
            if (this.$store.state.isLoading) {
              this.$store.commit('SET_IS_LOADING', false);
            }
          });

        this.openDialogFormLink = false;
      }
    },
    async editLink() {
      if (this.$refs.linkForm.validate()) {
        this.formLink.url = formatUrl(this.formLink.url);
        this.$store.commit('SET_IS_LOADING', true);
        await this.$http
          .put('/api/user/links', this.formLink)
          .then((response) => {
            if (response.data.success) {
              this.$store.dispatch('moduleDocuments/fetchLinks');
            }
          })
          .catch(() => {
            this.$store.commit('SET_HAS_ERROR', true);
          })
          .finally(() => {
            if (this.$store.state.isLoading) {
              this.$store.commit('SET_IS_LOADING', false);
            }
          });

        this.openDialogFormLink = false;

        // Reset form
        this.currentLink = undefined;
        this.formLink.id = null;
        this.formLink.title = '';
        this.formLink.url = '';
      }
    },
    async deleteLink(response = 'Delete') {
      if (response === 'Delete') {
        this.$store.commit('SET_IS_LOADING', true);

        await this.$http
          .delete('/api/user/links/' + this.currentLink.id)
          .then((response) => {
            if (response.data.success) {
              this.$store.dispatch('moduleDocuments/fetchLinks');
            }
          })
          .catch(() => {
            this.$store.commit('SET_HAS_ERROR', true);
          })
          .finally(() => {
            if (this.$store.state.isLoading) {
              this.$store.commit('SET_IS_LOADING', false);
            }
          });
      }

      this.currentLink = undefined;
      this.openDialogDeleteLink = false;
    },
  },
};
</script>
