<template>
  <div
    class="brightcove-main mx-auto"
    v-if="videoId"
  >
    <iframe
      class="brightcove-iframe"
      :src="`https://players.brightcove.net/6110393209001/default_default/index.html?videoId=${videoId}`"
      allow="encrypted-media"
      allowfullscreen
    />
  </div>
</template>

<script>
export default {
  props: {
    videoId: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.brightcove-main {
  height: 60vh;
  width: 90%;
}

.brightcove-iframe {
  width: 100%;
  height: 100%;
  display: block;
  width: 100%;
}
</style>
