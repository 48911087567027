/**
 * This file contains functions that enhance the event system on Vue.prototype
 * Please also refer to the ADS Developer's Guide - Component Design Patterns
 * https://ads.education.nsw.gov.au/developerguide/adsdesignpatterns
 */

/**
 * Global event bus
 * @param Vue Vue constructor function
 * `$eventHub` is used in some components such as `AppBar` and `NavigationDrawer/Notifications` for components communication.
 */
export function useEventHub(Vue) {
  Vue.prototype.$eventHub = new Vue();
}

/**
 * Implement Upward Broadcast event mechanism
 * @param Vue Vue constructor function
 */
export function useUpwardBroadcast(Vue) {
  /**
   * Broadcast an hooks from the current component upward to all the ancestors
   * @param event: string - the hooks being broadcast upward from the current component
   * @param args: any - the data being passed when emitting the hooks
   */
  Vue.prototype.$upwardBroadcast = function (event, args) {
    let parent = this.$parent;
    while (parent) {
      parent.$emit(event, args);
      parent = parent.$parent;
    }
  };
}

/**
 * Implement Downward Broadcast event mechanism
 * @param Vue Vue constructor function
 */
export function useDownwardBroadcast(Vue) {
  /**
   * Broadcast an hooks from the current component downward to all the descendants
   * @param event: string - the hooks being broadcast downward from the current component
   * @param args: any - the data being passed when emitting the hooks
   */
  Vue.prototype.$downwardBroadcast = function (event, args) {
    const downwardBroadcast = (children) => {
      children.forEach((child) => {
        child.$emit(event, args);
        if (child.$children) {
          downwardBroadcast(child.$children);
        }
      });
    };
    downwardBroadcast(this.$children);
  };
}
