<template>
  <div class="light10 fill-height">
    <Banner
      icon="help_outline"
      contentClass="ml-2"
      heading="Support"
      subtitle="Guides and support for performance and accreditation"
      backgroundImageUrl="images/page-banner.png"
    />

    <div>
      <TabNavigation
        v-if="!loading"
        :grow="$vuetify.breakpoint.xlAndDown"
        mobile-breakpoint="xs"
        :items="tabs"
        slider-color="#D7153A"
        slider-size="4"
      >
        <!-- Support Links -->
        <template v-slot:content1>
          <SupportLinks />
        </template>
        <!-- ...  -->

        <!-- Surveys -->
        <template v-slot:content2>
          <FeedbackLinks />

          <!-- The container below is for embedded Sharepoint iframe - Sharepoint website to be built -->
          <!-- More info in README -->
          <!-- <Sharepoint iframe /> -->
          <!-- <v-container
            fluid
            class="d-flex"
          >
            <iframe
              src="https://schoolsnsw.sharepoint.com/_layouts/15/sharepoint.aspx"
              title="Q+ Feedback Survey Sharepoint"
            /> -->
          <!-- TODO: URL will need to be updated with actual Q+ Sharepoint site -->
          <!-- </v-container> -->
        </template>
        <!-- ... -->
      </TabNavigation>
    </div>
  </div>
</template>

<script>
import { Banner } from '@nswdoe/doe-ui-core';
import { TabNavigation } from '@nswdoe/doe-ui-core';
import SupportLinks from './SupportLinks.vue';
import FeedbackLinks from './FeedbackLinks.vue';

export default {
  title: 'Help - Q+',
  components: {
    Banner,
    TabNavigation,
    SupportLinks,
    FeedbackLinks,
  },

  data() {
    return {
      loading: false,
      tabs: [{ tab: 'Support' }],
    };
  },
};
</script>

<style lang="scss" scoped>
iframe {
  width: 100%;
  height: 100vh;
  flex-grow: 1;
}

::v-deep {
  .v-main__wrap {
    display: flex;
  }
  .v-tab {
    text-align: center;
    width: 100%;
    padding: 5px 2px;
  }
}
</style>
