<template>
  <div class="light10 container-wrapper">
    <Banner
      icon="person_pin"
      contentClass="ml-2"
      class="display-banner"
      heading="My Details"
      subtitle="Your information accessible in one place"
      backgroundImageUrl="images/page-banner.png"
    />

    <v-card>
      <!-- Teacher's Basic Details (Name, School, Avatar)-->
      <div class="align-self-center d-flex pa-6 mt-6 border-btm">
        <v-avatar
          round
          size="50"
          color="#C7DCF0"
          class="mr-5"
        >
          <img
            v-if="userAvatar"
            :src="userAvatar"
            alt="profile image"
          >
          <span v-else>
            {{ userInitialName }}
          </span>
        </v-avatar>
        <div>
          <h2>
            {{ userProfile.FirstName }} {{ userProfile.LastName }},
            {{ userProfile.MainEducationFacility }}
          </h2>
          <div>
            <strong>Accreditation Level:</strong>
            {{ userProfile.AccreditationStatus }}
          </div>
        </div>
      </div>

      <!-- Teacher's Data  -->
      <v-container class="pl-8">
        <h3 class="my-4">
          Your current status
        </h3>
        <v-row
          no-gutters
          class="mb-2"
        >
          <v-col
            cols="3"
            class="font-weight-bold"
          >
            Accreditation Status:
          </v-col>
          <v-col
            cols="9"
            v-if="isMaintainingAccreditation"
          >
            Maintaining Proficient Teacher Accreditation
          </v-col>
          <v-col
            cols="9"
            v-if="isGainingAccreditation"
          >
            Gaining Proficient Teacher Accreditation
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="mb-2"
        >
          <v-col
            cols="3"
            v-if="isMaintainingAccreditation"
            class="font-weight-bold"
          >
            Maintenance period:
          </v-col>
          <v-col
            cols="3"
            v-if="isGainingAccreditation"
            class="font-weight-bold"
          >
            Proficient teacher period:
          </v-col>
          <v-col
            cols="9"
            v-if="isMaintainingAccreditation"
          >
            {{ userProfile.MOACurrentPeriodStartDate | dateFormat }} –
            {{ userProfile.MOACurrentPeriodEndDate | dateFormat }}
          </v-col>

          <v-col
            cols="9"
            v-if="isGainingAccreditation"
          >
            {{ userProfile.StartDateAchievePT | dateFormat }} –
            {{ userProfile.AchievePTBy | dateFormat }}
          </v-col>
        </v-row>

        <v-row
          no-gutters
          class="mb-2"
        >
          <v-col
            cols="3"
            class="font-weight-bold"
          >
            NESA membership status:
          </v-col>
          <v-col
            cols="9"
            class="font-weight-bold d-flex align-center"
          >
            <v-avatar
              class="mr-1"
              size="10"
              color="success"
              v-show="
                userProfile.NESAMembershipStatus &&
                  userProfile.NESAMembershipStatus.toLowerCase() === 'active'
              "
            />
            <span class="primary--text text-uppercase">{{
              userProfile.NESAMembershipStatus
            }}</span>
          </v-col>
        </v-row>

        <!-- IDs & Certs Number -->
        <h3 class="mb-4 mt-11">
          Your details
        </h3>
        <v-row
          no-gutters
          class="mb-2"
        >
          <v-col
            cols="3"
            class="font-weight-bold"
          >
            NESA ID:
          </v-col>
          <v-col cols="9">
            {{ userProfile.NESAAccountNumber }}
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="mb-2"
        >
          <v-col
            cols="3"
            class="font-weight-bold"
          >
            WWCC number:
          </v-col>
          <v-col cols="9">
            {{ userProfile.WWCNumber }}
          </v-col>
        </v-row>
        <v-row
          no-gutters
          class="mb-2"
        >
          <v-col
            cols="3"
            class="font-weight-bold"
          >
            DoE ID:
          </v-col>
          <v-col cols="9">
            {{ doeId }}
          </v-col>
        </v-row>
      </v-container>

      <!-- eTAMS note -->
      <div
        class="pa-8"
        v-if="dashboardContent"
      >
        <!-- NESA Elective PD Change message -->
        <div
          class="d-flex pt-5 px-5 justify-start dark70--text light10 message-box"
        >
          <!-- <v-icon
            aria-hidden="true"
            class="warning--text warning-icon pr-2"
          >
            error
          </v-icon> -->
          <div
            class="font-weight-bold"
            v-html="dashboardContent.nesa_teacher_accreditation_reform_message"
          />
        </div>
        <!-- End of NESA Elective PD Change message -->

        <div
          class="py-5 px-5 dark70--text light10 message-box"
          v-html="dashboardContent.profile_details_message_box"
        />
      </div>
      <!-- end of eTAMS note -->
    </v-card>
  </div>
</template>

<script>
import { Banner } from '@nswdoe/doe-ui-core';
import { mapGetters, mapState } from 'vuex';

export default {
  title: 'My Details - Q+',
  data() {
    return {
      showFooter: true,
      loading: true,
    };
  },
  components: {
    Banner,
  },
  computed: {
    ...mapGetters({
      userProfile: 'moduleUserProfile/userProfile',
      isGainingAccreditation: 'moduleUserProfile/isGainingAccreditation',
      isMaintainingAccreditation:
        'moduleUserProfile/isMaintainingAccreditation',
      userInitialName: 'moduleUserProfile/userInitialName',
      userAvatar: 'moduleUserProfile/userAvatar',
    }),
    ...mapState('moduleStaticContent', {
      dashboardContent: (state) => state.content.dashboard,
    }),
    doeId() {
      // Not 100% sure if this is right but it seems there can be multiple sources of doe employee ids
      return (
        this.$OAuth.data.profile.DETSAPEmployeeId ||
        this.$OAuth.data.profile.DETPEPEmployeeId ||
        this.$OAuth.data.profile.DETCEPEmployeeId
      );
    },
  },
  methods: {
    actionHandler() {},
  },
  created() {
    this.$store.dispatch('moduleUserProfile/fetchAvatar');
    this.$store.commit('SET_IS_LOADING', true);
    this.loading = true;
    Promise.all([
      this.$store.dispatch('moduleUserProfile/fetchUserProfile'),
      this.$store.dispatch(
        'moduleStaticContent/fetchStaticContent',
        'dashboard'
      ),
    ]).finally(() => {
      this.$store.commit('SET_IS_LOADING', false);
      this.loading = false;
    });
  },
};
</script>

<style lang="scss" scoped>
.display-banner {
  background-position: right 20%;
  font-size: 34px;
}

.container-wrapper {
  height: 100%;
}

.border-btm {
  border-bottom: 1px solid $ads-light-40;
}

.font-weight-bold {
  line-height: 24px;
}

::v-deep {
  .v-card {
    width: 1046px;
    min-height: 0px;
    margin: auto;
    color: $ads-grey-01;

    h3 {
      color: $ads-primary-blue;
    }
  }
}
</style>
