const moduleTasks = {
  namespaced: true,
  state: {
    completedTasks: undefined,
  },
  getters:{
    completedTasks: (state) => state.completedTasks,
  },
  mutations: {
    SET_COMPLETED_TASKS(state, completedTasks) {
      state.completedTasks = completedTasks;
    },
    TOGGLE_COMPLETED_TASK(state, id) {
      if (!state.completedTasks.includes(id)) {
        state.completedTasks.push(id);
      } else {
        const index = state.completedTasks.indexOf(id);
        state.completedTasks.splice(index, 1);
      }
    },
  },
  actions: {
    fetchCompletedTasks({commit, state}) {
      if (!state.completedTasks) {
        return this._vm.$http.get("/api/user/pdjourney")
          .then(response => {
            commit('SET_COMPLETED_TASKS', response.data.filter(val => val.value === "true").map(val => val.key));
          })
          .catch(() => {
            commit('SET_HAS_ERROR', true, {root: true});
          })
      }
    },
    toggleCompletedTask({commit, state}, id) {
      return this._vm.$http.put("/api/user/pdjourney", {
        key: id,
        value: !state.completedTasks.includes(id)
      })
        .then(() => {
          commit('TOGGLE_COMPLETED_TASK', id);
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, {root: true});
        })
    }
  }
};

export default moduleTasks;
