<template>
  <div class="container-wrapper">
    <v-container
      class="container-main py-6"
      fluid
    >
      <div v-if="helpSupport">
        <h2 class="title font-weight-bold mb-4 primary--text">
          {{ helpSupport.title }}
        </h2>

        <v-row class="mb-6">
          <v-col
            v-for="item in helpSupport.items"
            :key="item.title"
            md="6"
            sm="12"
          >
            <v-card class="fill-height">
              <div class="d-flex pa-5">
                <div class="d-none d-md-block">
                  <v-img
                    width="96px"
                    height="96px"
                    alt="icon"
                    contain
                    :src="thumbnails[item.image]"
                  />
                </div>
                <div class="ml-5">
                  <h3 class="title font-weight-bold primary--text">
                    {{ item.title }}
                  </h3>
                  <div
                    class="body-1 primary--text"
                    v-if="item.description"
                    v-html="item.description"
                  />

                  <v-list dense>
                    <v-list-item
                      class="px-0"
                      v-if="item.website_url_1"
                    >
                      <v-list-item-icon class="mr-2 my-1">
                        <span class="d-sr-only"> Website </span>
                        <v-icon
                          aria-hidden="true"
                          color="primary"
                        >
                          public
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="py-0">
                        <a
                          class="body-1 primary--text"
                          target="_blank"
                          :href="item.website_url_1"
                        >{{ item.website_text_1 }}</a>
                      </v-list-item-content>
                    </v-list-item>

                    <div
                      class="body-1 primary--text"
                      v-if="item.web_2_description"
                      v-html="item.web_2_description"
                    />
                    <v-list-item
                      class="px-0"
                      v-if="item.website_url_2"
                    >
                      <v-list-item-icon class="mr-2 my-1">
                        <span class="d-sr-only"> Website </span>
                        <v-icon
                          aria-hidden="true"
                          color="primary"
                        >
                          public
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="py-0">
                        <a
                          class="body-1 primary--text"
                          target="_blank"
                          :href="item.website_url_2"
                        >{{ item.website_text_2 }}</a>
                      </v-list-item-content>
                    </v-list-item>

                    <div
                      class="body-1 primary--text"
                      v-if="item.web_3_description"
                      v-html="item.web_3_description"
                    />
                    <v-list-item
                      class="px-0"
                      v-if="item.website_url_3"
                    >
                      <v-list-item-icon class="mr-2 my-1">
                        <span class="d-sr-only"> Website </span>
                        <v-icon
                          aria-hidden="true"
                          color="primary"
                        >
                          public
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="py-0">
                        <a
                          class="body-1 primary--text"
                          target="_blank"
                          :href="item.website_url_3"
                        >{{ item.website_text_3 }}</a>
                      </v-list-item-content>
                    </v-list-item>

                    <div
                      class="body-1 primary--text"
                      v-if="item.web_4_description"
                      v-html="item.web_4_description"
                    />
                    <v-list-item
                      class="px-0"
                      v-if="item.website_url_4"
                    >
                      <v-list-item-icon class="mr-2 my-1">
                        <span class="d-sr-only"> Website </span>
                        <v-icon
                          aria-hidden="true"
                          color="primary"
                        >
                          public
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="py-0">
                        <a
                          class="body-1 primary--text"
                          target="_blank"
                          :href="item.website_url_4"
                        >{{ item.website_text_4 }}</a>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      class="px-0"
                      v-if="item.email"
                    >
                      <v-list-item-icon class="mr-2 my-1">
                        <span class="d-sr-only"> Email address </span>
                        <v-icon
                          aria-hidden="true"
                          color="primary"
                        >
                          alternate_email
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="py-0">
                        <a
                          class="body-1 primary--text"
                          :href="`mailto:${item.email}`"
                        >{{ item.email }}</a>
                      </v-list-item-content>
                    </v-list-item>

                    <v-list-item
                      v-if="item.phone"
                      class="px-0"
                    >
                      <v-list-item-icon class="mr-2 my-1">
                        <span class="d-sr-only"> Phone number </span>
                        <v-icon
                          aria-hidden="true"
                          color="primary"
                        >
                          phone_iphone
                        </v-icon>
                      </v-list-item-icon>
                      <v-list-item-content class="py-0">
                        <a
                          class="body-1 primary--text"
                          :href="`tel:${item.phone}`"
                        >{{ item.phone }}</a>
                      </v-list-item-content>
                    </v-list-item>
                  </v-list>
                </div>
              </div>
            </v-card>
          </v-col>
        </v-row>
      </div>

      <div v-if="moreLinks">
        <h2 class="title font-weight-bold mb-4 primary--text">
          {{ moreLinks.title }}
        </h2>

        <v-card class="px-5 py-8">
          <v-row>
            <v-col
              v-for="item in moreLinks.items"
              :key="item.text"
              cols="12"
              md="6"
            >
              <div>
                <v-icon
                  aria-hidden="true"
                  color="primary"
                  class="mr-2"
                >
                  mdi-open-in-new
                </v-icon>
                <a
                  class="primary--text"
                  target="_blank"
                  :href="item.url"
                >{{
                  item.text
                }}</a>
              </div>
            </v-col>
          </v-row>
        </v-card>
      </div>
    </v-container>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'SupportLinks',
  data() {
    return {
      thumbnails: {},
    };
  },
  computed: {
    ...mapState('moduleStaticContent', {
      helpSupport: (state) => state.content.help_support,
      moreLinks: (state) => state.content.more_links,
    }),
  },
  watch: {
    helpSupport: {
      immediate: true,
      handler(helpSupport) {
        helpSupport?.items?.forEach((item) => {
          if (item.image) {
            this.$set(this.thumbnails, item.image, '');
          }
        });
        Object.keys(this.thumbnails).forEach((thumbnailName) => {
          this.generateThumbnail(thumbnailName);
        });
      },
    },
  },
  created() {
    this.$store.dispatch(
      'moduleStaticContent/fetchStaticContent',
      'help_support'
    );
    this.$store.dispatch(
      'moduleStaticContent/fetchStaticContent',
      'more_links'
    );
  },
  methods: {
    async generateThumbnail(fileName) {
      await this.$http
        .get('/api/static/resources/thumbnail/' + fileName, {
          responseType: 'blob',
        })
        .then((response) => {
          this.$set(
            this.thumbnails,
            fileName,
            URL.createObjectURL(response.data)
          );
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.container-wrapper,
.container-main {
  background-color: $ads-light-10;
}
</style>
