<template>
  <v-navigation-drawer
    v-bind="$attrs"
    v-on="$listeners"
    v-model="drawer"
    :app="app"
    :right="right"
    :clipped="clipped"
    :width="width"
    :color="$vuetify.theme.dark ? ADS_Colors.DP02 : ADS_Colors.Light_10"
    :temporary="temporary"
    tabindex="0"
    ref="navRoot"
  >
    <div class="notifications">
      <v-card
        flat
        v-if="notifications.length > 0"
      >
        <v-card-title
          class="d-flex contrast notificationTitle justify-space-between py-4 pr-1"
          :style="{
            backgroundColor: $vuetify.theme.dark
              ? ADS_Colors.DP02
              : ADS_Colors.Navy,
          }"
        >
          <span
            role="heading"
            tabindex="0"
            :aria-label="notificationData.length + 'notification(s)'"
            class="title"
            :style="{ color: ADS_Colors.White }"
          >
            Notifications
            <span role="title">
              <v-badge
                :content="notificationData.length"
                color="red"
                class="ml-2"
              />
            </span>
          </span>
          <span
            role="button"
            class="pr-5"
          >
            <v-btn
              aria-label="close notifications"
              @click="$eventHub.$emit('toggle:notifications')"
              @keyup.enter="$eventHub.$emit('toggle:notifications')"
              size="18"
              class="size--40"
              icon
            >
              <v-icon
                size="24"
                :color="ADS_Colors.White"
              > close </v-icon>
            </v-btn>
          </span>
        </v-card-title>

        <v-divider class="divider" />

        <v-card-text class="pa-0">
          <v-list
            class="notification--contents contrast"
            flat
            style="padding-bottom: 115px"
            three-line
          >
            <template v-for="(item, index) in notifications">
              <v-list-item
                class="pl-5 pr-2 py-0"
                role="row"
                :key="'notification' + index"
                :aria-label="'notification item ' + (index + 1)"
                :style="
                  index < notifications.length - 1
                    ? 'border-bottom:1px solid #A0A5AE'
                    : ''
                "
              >
                <template v-slot:default="{}">
                  <v-list-item-icon class="mr-4">
                    <div
                      class="d-flex align-self-center"
                      style="height: 36px"
                    >
                      <AppIcon
                        v-if="item.icon"
                        :app-name="item.icon"
                        size="40"
                        style="height: 24px; width: 24px"
                        class="mt-0 align-self-center mr-4"
                      />
                      <div
                        v-if="!item.icon"
                        class="warning align-self-center d-flex"
                        style="height: 40px; width: 40px; border-radius: 4px"
                      >
                        <v-icon
                          size="30"
                          class="ma-auto"
                          style="width: 100%"
                          :color="ADS_Colors.White"
                        >
                          warning_amber
                        </v-icon>
                      </div>
                    </div>
                  </v-list-item-icon>

                  <v-list-item-content
                    tabindex="0"
                    :aria-label="
                      item.title +
                        ' ' +
                        item.content +
                        ' ' +
                        (item.time != '' ? timeSince(item.time) + ' ago' : '')
                    "
                  >
                    <div
                      class="subtitle-1"
                      v-text="showTruncated(item.title, 39)"
                      :title="item.title"
                      tabindex="0"
                    />
                    <div
                      class="pb-4 body-2 pr-2"
                      tabindex="0"
                      :title="item.content"
                    >
                      <span>{{ showTruncated(item.content, 150) }}</span><span
                        v-if="item.link"
                        class="pl-2"
                      >
                        <a
                          target="_blank"
                          :href="item.link"
                          class="qplus-drawer"
                          aria-label="read more link double tap to open in new tab"
                          style="text-decoration: none"
                        >
                          Read More<v-icon size="12">launch</v-icon>
                        </a>
                      </span>
                    </div>

                    <div class="d-flex pb-0 mb-0">
                      <div
                        class="d-flex grow align-self-center justify-space-between"
                      >
                        <span
                          class="caption align-self-center text-color"
                          style="max-width: 90%"
                        >
                          {{ item.fromAppName }}
                          <span class="font-weight-bold"> - </span>
                          {{
                            item.time != '' ? timeSince(item.time) + ' ago' : ''
                          }}
                        </span>
                      </div>
                    </div>
                  </v-list-item-content>

                  <v-list-item-icon class="ml-0">
                    <span class="mt-n1">
                      <v-btn
                        :aria-label="'delete notification ' + item.title"
                        @click="handleDelete(item)"
                        style="width: 40px; height: 40px"
                        class="pa-0"
                        icon
                      >
                        <v-icon
                          size="20"
                          :color="ADS_Colors.Dark_60"
                        >
                          close
                        </v-icon>
                      </v-btn>
                    </span>
                  </v-list-item-icon>
                </template>
              </v-list-item>
            </template>
          </v-list>
        </v-card-text>
      </v-card>
      <v-card
        flat
        v-if="notifications.length == 0"
        style="text-align: center"
        class="flex"
      >
        <div
          role=""
          class="pt-10"
        >
          <span class="pt-4 pb-7 flex-start">
            <img
              alt="no notification image"
              src="./no_notification.svg"
            >
          </span>
        </div>
        <span
          role="heading"
          class="noItem"
        > No notifications </span>
      </v-card>
    </div>
  </v-navigation-drawer>
</template>

<script>
import { AppIcon } from '@nswdoe/doe-ui-core';
import orderBy from 'lodash/orderBy';
import { ADS_Colors } from '@nswdoe/doe-ui-core';
import { gtmClickOnNotificationDrawer } from './../../plugins/gtm-utils';

export default {
  name: 'CustomNotifications',
  components: {
    AppIcon,
  },
  props: {
    app: {
      type: Boolean,
      default: true, // override the default value 'false' in v-navigation-drawer
    },
    right: {
      type: Boolean,
      default: true, // override the default value 'false' in v-navigation-drawer
    },
    width: {
      type: [Number, String],
      default: '360', // override the default value '256' in v-navigation-drawer
    },
    clipped: {
      type: Boolean,
      default: true, // override the default value 'false' in v-navigation-drawer
    },
    temporary: {
      type: Boolean,
      default: true, // override the default value 'false' in v-navigation-drawer
    },
    notificationData: {
      type: Array,
      default: function () {
        return [];
      },
    },
    iconsToMap: {
      type: Array,
      default: function () {
        return [
          { app: 'mypl', icon: 'my-my-pl-edu' },
          { app: 'schoolbiz', icon: 'my-school-biz' },
          { app: 'cica', icon: 'my-ci-checklist' },
        ];
      },
    },
  },
  data() {
    return {
      drawer: false,
      ADS_Colors,
    };
  },
  mounted() {
    // adding eventHub listener
    this.$eventHub.$on('toggle:notifications', this.toggleNotifications);
  },
  beforeDestroy() {
    // removing eventHub listener
    this.$eventHub.$off('toggle:notifications');
  },
  computed: {
    notifications() {
      let notificationData = orderBy(
        this.notificationData,
        function (item) {
          return item.receivedDatetime;
        },
        'desc'
      );
      return notificationData && notificationData.length > 0
        ? notificationData.map((item) => {
            return {
              id: item.id,
              fromAppName: this.mapApplication(item.fromAppName),
              icon: this.mapAppIcon(item.fromAppName),
              title: item.messageTitle ? item.messageTitle : '',
              content:
                item.messageContent && item.messageContent.body
                  ? item.messageContent.body
                  : item.messageBody
                  ? item.messageBody
                  : '',
              link: item.messageDeeplink
                ? item.messageDeeplink
                : item.messageContent &&
                  item.messageContent.data &&
                  item.messageContent.data.deeplink
                ? item.messageContent.data.deeplink
                : null,
              time: item.receivedDatetime ? item.receivedDatetime : '',
              read: item.readDatetime && item.readDatetime != '' ? true : false,
              requestId: item.requestId,
            };
          })
        : [];
    },
  },
  methods: {
    toggleNotifications() {
      this.drawer = !this.drawer;
      if (this.drawer) this.$refs.navRoot.$el.focus();
      gtmClickOnNotificationDrawer(this.$gtm, this.notifications);
    },
    mapApplication(appName) {
      return appName
        ? appName.replace('My', 'My ').replace('School', 'School ')
        : 'Critical Alert';
    },
    mapAppIcon(appName) {
      let icon = '';
      if (appName) {
        this.iconsToMap.forEach((iconMap) => {
          if (iconMap.app == appName.toLowerCase()) {
            icon = iconMap.icon;
          }
        });
      }
      return icon;
    },
    showTruncated(message, limit) {
      if (message.length > limit + 3) {
        message = message.substring(0, limit) + '...';
      }
      return message;
    },
    timeSince(timestamp) {
      let date = new Date(timestamp);
      let seconds = Math.floor((new Date() - date) / 1000);
      let interval = Math.floor(seconds / 31536000);
      if (interval > 1) {
        return interval + ' years';
      }
      interval = Math.floor(seconds / 2592000);
      if (interval > 1) {
        return interval + ' months';
      }
      interval = Math.floor(seconds / 2592000);
      if (interval == 1) {
        return interval + ' month';
      }
      interval = Math.floor(seconds / 86400);
      if (interval > 1) {
        return interval + ' days';
      }
      interval = Math.floor(seconds / 3600);
      if (interval > 1) {
        return interval + ' hours';
      }
      interval = Math.floor(seconds / 60);
      if (interval > 1) {
        return interval + ' minutes';
      }
      return Math.floor(seconds) + ' seconds';
    },
    handleDelete(notification) {
      this.$emit('onDelete', notification);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@nswdoe/doe-ui-core/styles/variables.scss';

.notifications {
  overflow: hidden;

  .divider {
    top: 70px;
    z-index: 1000;
    position: fixed !important;
    overflow: hidden;
    width: 100%;
    opacity: 1;
  }

  .notificationTitle {
    position: fixed !important;
    overflow: hidden;
    width: 100%;
    z-index: 1000;
    opacity: 1;
  }

  .notification--contents {
    top: 70px;
    position: relative;
    max-height: 100vh;
    overflow: scroll;
  }

  .size--40 {
    width: 40px;
    height: 40px;
  }

  .v-sheet {
    border-radius: 0;
  }
}

.v-overlay__scrim {
  opacity: 0 !important;
}

.notifications svg {
  height: 24px;
  width: 24px;
}

.v-overlay v-overlay--active {
  z-index: 0 !important;
}

.caption {
  font-size: 14px !important;
  font-weight: 400;
  line-height: 1rem;
}

.text-color {
  color: $ads-dark-70;
}
</style>
