var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-navigation-drawer',_vm._g(_vm._b({ref:"navRoot",attrs:{"app":_vm.app,"right":_vm.right,"clipped":_vm.clipped,"width":_vm.width,"color":_vm.$vuetify.theme.dark ? _vm.ADS_Colors.DP02 : _vm.ADS_Colors.Light_10,"temporary":_vm.temporary,"tabindex":"0"},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},'v-navigation-drawer',_vm.$attrs,false),_vm.$listeners),[_c('div',{staticClass:"notifications"},[(_vm.notifications.length > 0)?_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"d-flex contrast notificationTitle justify-space-between py-4 pr-1",style:({
          backgroundColor: _vm.$vuetify.theme.dark
            ? _vm.ADS_Colors.DP02
            : _vm.ADS_Colors.Navy,
        })},[_c('span',{staticClass:"title",style:({ color: _vm.ADS_Colors.White }),attrs:{"role":"heading","tabindex":"0","aria-label":_vm.notificationData.length + 'notification(s)'}},[_vm._v(" Notifications "),_c('span',{attrs:{"role":"title"}},[_c('v-badge',{staticClass:"ml-2",attrs:{"content":_vm.notificationData.length,"color":"red"}})],1)]),_c('span',{staticClass:"pr-5",attrs:{"role":"button"}},[_c('v-btn',{staticClass:"size--40",attrs:{"aria-label":"close notifications","size":"18","icon":""},on:{"click":function($event){return _vm.$eventHub.$emit('toggle:notifications')},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;return _vm.$eventHub.$emit('toggle:notifications')}}},[_c('v-icon',{attrs:{"size":"24","color":_vm.ADS_Colors.White}},[_vm._v(" close ")])],1)],1)]),_c('v-divider',{staticClass:"divider"}),_c('v-card-text',{staticClass:"pa-0"},[_c('v-list',{staticClass:"notification--contents contrast",staticStyle:{"padding-bottom":"115px"},attrs:{"flat":"","three-line":""}},[_vm._l((_vm.notifications),function(item,index){return [_c('v-list-item',{key:'notification' + index,staticClass:"pl-5 pr-2 py-0",style:(index < _vm.notifications.length - 1
                  ? 'border-bottom:1px solid #A0A5AE'
                  : ''),attrs:{"role":"row","aria-label":'notification item ' + (index + 1)},scopedSlots:_vm._u([{key:"default",fn:function({}){return [_c('v-list-item-icon',{staticClass:"mr-4"},[_c('div',{staticClass:"d-flex align-self-center",staticStyle:{"height":"36px"}},[(item.icon)?_c('AppIcon',{staticClass:"mt-0 align-self-center mr-4",staticStyle:{"height":"24px","width":"24px"},attrs:{"app-name":item.icon,"size":"40"}}):_vm._e(),(!item.icon)?_c('div',{staticClass:"warning align-self-center d-flex",staticStyle:{"height":"40px","width":"40px","border-radius":"4px"}},[_c('v-icon',{staticClass:"ma-auto",staticStyle:{"width":"100%"},attrs:{"size":"30","color":_vm.ADS_Colors.White}},[_vm._v(" warning_amber ")])],1):_vm._e()],1)]),_c('v-list-item-content',{attrs:{"tabindex":"0","aria-label":item.title +
                      ' ' +
                      item.content +
                      ' ' +
                      (item.time != '' ? _vm.timeSince(item.time) + ' ago' : '')}},[_c('div',{staticClass:"subtitle-1",attrs:{"title":item.title,"tabindex":"0"},domProps:{"textContent":_vm._s(_vm.showTruncated(item.title, 39))}}),_c('div',{staticClass:"pb-4 body-2 pr-2",attrs:{"tabindex":"0","title":item.content}},[_c('span',[_vm._v(_vm._s(_vm.showTruncated(item.content, 150)))]),(item.link)?_c('span',{staticClass:"pl-2"},[_c('a',{staticClass:"qplus-drawer",staticStyle:{"text-decoration":"none"},attrs:{"target":"_blank","href":item.link,"aria-label":"read more link double tap to open in new tab"}},[_vm._v(" Read More"),_c('v-icon',{attrs:{"size":"12"}},[_vm._v("launch")])],1)]):_vm._e()]),_c('div',{staticClass:"d-flex pb-0 mb-0"},[_c('div',{staticClass:"d-flex grow align-self-center justify-space-between"},[_c('span',{staticClass:"caption align-self-center text-color",staticStyle:{"max-width":"90%"}},[_vm._v(" "+_vm._s(item.fromAppName)+" "),_c('span',{staticClass:"font-weight-bold"},[_vm._v(" - ")]),_vm._v(" "+_vm._s(item.time != '' ? _vm.timeSince(item.time) + ' ago' : '')+" ")])])])]),_c('v-list-item-icon',{staticClass:"ml-0"},[_c('span',{staticClass:"mt-n1"},[_c('v-btn',{staticClass:"pa-0",staticStyle:{"width":"40px","height":"40px"},attrs:{"aria-label":'delete notification ' + item.title,"icon":""},on:{"click":function($event){return _vm.handleDelete(item)}}},[_c('v-icon',{attrs:{"size":"20","color":_vm.ADS_Colors.Dark_60}},[_vm._v(" close ")])],1)],1)])]}}],null,true)})]})],2)],1)],1):_vm._e(),(_vm.notifications.length == 0)?_c('v-card',{staticClass:"flex",staticStyle:{"text-align":"center"},attrs:{"flat":""}},[_c('div',{staticClass:"pt-10",attrs:{"role":""}},[_c('span',{staticClass:"pt-4 pb-7 flex-start"},[_c('img',{attrs:{"alt":"no notification image","src":require("./no_notification.svg")}})])]),_c('span',{staticClass:"noItem",attrs:{"role":"heading"}},[_vm._v(" No notifications ")])]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }