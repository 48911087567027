import _ from 'lodash';

const MILISECS_IN_SEC = 1000;
const SECS_IN_HOUR = 3600;
const MINS_IN_HOUR = 60;
const HOURS_IN_DAY = 24;
const DAYS_IN_MONTH = 30;
const DAYS_IN_WEEK = 7;
const DAYS_IN_YEAR = 365;

// Vuex UserProfile module
const moduleUserProfile = {
  namespaced: true,
  state: {
    userProfile: {},
    mandatoryTrainingList: {},
    userAvatar: undefined,
  },
  getters: {
    userProfile: (state) => state.userProfile,
    mandatoryTrainingList: (state) => state.mandatoryTrainingList,
    userAvatar: (state) => state.userAvatar,
    isGainingAccreditation: (state) => {
      const accreditationArray = [
        'Conditional',
        'Provisional',
        'Provisional - Reaccredited',
        'Pending Proficient',
      ];
      if (
        !_.isEmpty(state.userProfile) &&
        accreditationArray.includes(state.userProfile.AccreditationStatus)
      ) {
        return true;
      }
      return false;
    },
    isMaintainingAccreditation: (state) => {
      const accreditationArray = [
        'Proficient Teacher',
        'Highly Accomplished',
        'Lead Teacher',
      ];
      if (
        !_.isEmpty(state.userProfile) &&
        accreditationArray.includes(state.userProfile.AccreditationStatus)
      ) {
        return true;
      }
      return false;
    },
    isHighlyAccomplished: (state) => {
      const accreditationArray = ['Highly Accomplished Teacher'];
      if (
        !_.isEmpty(state.userProfile) &&
        accreditationArray.includes(state.userProfile.HALTAccreditationLevel)
      ) {
        return true;
      }
      return false;
    },
    totalPDHours: (state) => {
      if (!_.isEmpty(state.userProfile)) {
        return (
          state.userProfile.AccreditedPDHoursEvaluated +
          state.userProfile.ElectivePDHours
        );
      }
    },
    accreditedPDHours: (state) => {
      return Math.round(
        state.userProfile.AccreditedPDHoursEvaluated / MINS_IN_HOUR
      );
    },
    electivePDHours: (state) => {
      return Math.round(state.userProfile.ElectivePDHours / MINS_IN_HOUR);
    },
    nonEvaluatedAccreditedPDHours: (state) => {
      return Math.round(
        state.userProfile.AccreditedPDHoursNonEvaluated / MINS_IN_HOUR
      );
    },
    endOfMaintenancePeriod: (state) => {
      if (!_.isEmpty(state.userProfile)) {
        const dateObj = new Date(state.userProfile.MOACurrentPeriodEndDate);
        const dateFormatter = new Intl.DateTimeFormat('en-GB', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });

        const dateParts = dateFormatter.formatToParts(dateObj);
        return dateParts;
      }
    },

    maintenancePeriodElapsedDays: (state) => {
      if (!_.isEmpty(state.userProfile)) {
        const currentDate = new Date();
        const startDate = new Date(state.userProfile.MOACurrentPeriodStartDate);

        return (
          (currentDate.getTime() - startDate.getTime()) /
          (MILISECS_IN_SEC * SECS_IN_HOUR * HOURS_IN_DAY)
        );
      }
    },
    maintenancePeriodInDays: (state) => {
      if (!_.isEmpty(state.userProfile)) {
        const startDate = new Date(state.userProfile.MOACurrentPeriodStartDate);
        const endDate = new Date(state.userProfile.MOACurrentPeriodEndDate);
        return (
          (endDate.getTime() - startDate.getTime()) /
          (MILISECS_IN_SEC * SECS_IN_HOUR * HOURS_IN_DAY)
        );
      }
    },
    maintenancePeriodYearsUntilEnd: (state) => {
      if (!_.isEmpty(state.userProfile)) {
        const currentDate = new Date();
        const endDate = new Date(state.userProfile.MOACurrentPeriodEndDate);
        const daysFromNowToEnd =
          (endDate.getTime() - currentDate.getTime()) /
          (MILISECS_IN_SEC * SECS_IN_HOUR * HOURS_IN_DAY);
        return (daysFromNowToEnd / 360).toFixed(0);
      }
    },
    remainingTimeBreakdown: (state) => {
      if (!_.isEmpty(state.userProfile)) {
        const currentDate = new Date();
        const endDate = new Date(state.userProfile.MOACurrentPeriodEndDate);

        let daysRemaining =
          (endDate.getTime() - currentDate.getTime()) /
          (MILISECS_IN_SEC * SECS_IN_HOUR * HOURS_IN_DAY);
        const yearsRemaining = Math.floor(daysRemaining / DAYS_IN_YEAR);
        daysRemaining %= DAYS_IN_YEAR;
        const monthsRemaining = Math.floor(daysRemaining / DAYS_IN_MONTH);
        daysRemaining %= DAYS_IN_MONTH;
        const weeksRemaining = Math.floor(daysRemaining / DAYS_IN_WEEK);
        daysRemaining %= DAYS_IN_WEEK;

        return {
          yearsRemaining,
          monthsRemaining,
          weeksRemaining,
          daysRemaining,
        };
      }
    },
    userInitialName: (state) => {
      if (!_.isEmpty(state.userProfile)) {
        return `${
          state.userProfile.FirstName.charAt(0) +
          state.userProfile.LastName.charAt(0)
        }`;
      }
    },
  },
  mutations: {
    setProfile(state, profile) {
      state.userProfile = profile;
    },
    setMandatoryTrainingList(state, mandatoryTrainingList) {
      state.mandatoryTrainingList = mandatoryTrainingList;
    },
    setAvatar(state, avatar) {
      state.userAvatar = avatar;
    },
  },
  actions: {
    fetchAvatar({ commit, state }) {
      if (state.userAvatar === undefined) {
        return this._vm.$http
          .get('/api/user/profile/avatar', { responseType: 'blob' })
          .then((response) => {
            const avatarSrc = URL.createObjectURL(response.data);
            commit('setAvatar', avatarSrc);
          })
          .catch(() => {
            commit('setAvatar', null);
          });
      }
    },
    fetchUserProfile({ commit, state }) {
      if (_.isEmpty(state.userProfile)) {
        return this._vm.$http
          .get('/api/nesa/staffdetails')
          .then((response) => {
            if (
              response.data &&
              response.data.TeacherDetails &&
              response.data.TeacherDetails.length
            ) {
              return commit('setProfile', response.data.TeacherDetails[0]);
            } else {
              commit('SET_HAS_ERROR', true, { root: true });
            }
          })
          .catch(() => {
            commit('SET_HAS_ERROR', true, { root: true });
          });
      }
    },
    fetchMandatoryTrainingList({ commit, state }) {
      if (_.isEmpty(state.mandatoryTrainingList)) {
        return this._vm.$http
          .get('/api/user/profile/accreditations')
          .then((response) => {
            return commit(
              'setMandatoryTrainingList',
              response.data.staff.staffPositions
            );
          });
      }
    },
  },
};

export default moduleUserProfile;
