import axios from "axios";

// Vuex Essentials module
const moduleEssentials = {
  namespaced: true,
  state: {
    apps: []
  },
  mutations: {
    SET_MY_ESSENTIALS_APPS(state, apps) {
      state.apps = apps;
    }
  },
  actions: {
    fetchMyEssentialsApps({ commit }) {
      axios.get(process.env.VUE_APP_ESSENTIALS_API, {
        headers: {
          Authorization: 'Bearer ' + this._vm.$OAuth.data.accessToken,
          "id-token": this._vm.$OAuth.data.idToken,
          "aad": true,
        }
      })
        .then((response) => {
          return commit('SET_MY_ESSENTIALS_APPS', response.data);
        })
        .catch(() => {
        })
    },
  }
};

export default moduleEssentials;
