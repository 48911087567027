import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import { ADS_Colors } from '@nswdoe/doe-ui-core'; // use pre-defined ADS colors constants

Vue.use(Vuetify);

export default new Vuetify({
  customVariables: ['~/scss/ads.scss'],
  treeShake: true,
  icons: {
    iconfont: 'mdi',
  },
  theme: {
    themes: {
      light: {
        primary: ADS_Colors.PRIMARY_BLUE, //'#002664',
        secondary: ADS_Colors.Light_Blue, //'#C8DCF0',
        accent: ADS_Colors.PRIMARY_BLUE_DARK, // '#407EC9',
        success: ADS_Colors.Success_Green, // '#00AA45',
        error: ADS_Colors.Error_Red, // '#D7153A',
        dark: ADS_Colors.Dark, // '#121212',
        dark70: ADS_Colors.Dark_70, // '#4C4F55',
        light10: ADS_Colors.Light_10, // '#F4F4F7',
        secondary_grey: ADS_Colors.SECONDARY_GREY, // '#EBEBEB',
        warning: ADS_Colors.Warning_Orange, // '#F3631B',
      },
    },
  },
});
