<template>
  <Dialog
    :openDialog="openDialog"
    maxWidth="90%"
    :displayCloseBtn="true"
    @close="returnHandler"
    :return-handler="returnHandler"
    :fullHeight="true"
  >
    <v-container
      class="d-flex"
      v-if="item"
      slot="text"
    >
      <div
        class="survey"
        v-html="surveyForm"
      />
    </v-container>
  </Dialog>
</template>

<script>
import { Dialog } from '@nswdoe/doe-ui-core';

export default {
  name: 'FeedbackSurveyDialog',
  props: {
    openDialog: Boolean,
    returnHandler: {
      type: Function,
      default: () => {},
    },
    item: {
      type: Object,
      default: () => {
        return {};
      },
    },
    surveySrc: {
      type: String,
      default: '',
    },
  },
  components: {
    Dialog,
  },
  data() {
    return {};
  },
  computed: {
    surveyForm() {
      return `<iframe
          width="100%"
          height="100%"
          src="${this.item.surveySrc}"
          frameborder="0"
          marginwidth="0"
          marginheight="0"
          style="border: none; max-width: 100%; max-height: 100vh"
          allowfullscreen
          webkitallowfullscreen
          mozallowfullscreen
          msallowfullscreen
        ></iframe>`;
    },
  },
};
</script>

<style>
.v-dialog {
  display: flex;
  width: 80%;
  height: 80%;
}

.container {
  height: 90%;
}

.survey {
  flex-grow: 1;
}

col-5 {
  width: 30%;
}
</style>
