// Vuex Essentials module
const moduleDocuments = {
  namespaced: true,
  state: {
    documents: undefined,
    links: undefined,
    electives: undefined,
  },
  mutations: {
    SET_DOCUMENTS(state, documents) {
      state.documents = documents;
    },
    SET_LINKS(state, links) {
      state.links = links;
    },
    SET_ELECTIVES(state, electives) {
      state.electives = electives;
    },
  },
  actions: {
    fetchDocuments({ commit }) {
      return this._vm.$http
        .get('/api/user/documents')
        .then((response) => {
          commit('SET_DOCUMENTS', response.data);
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        });
    },
    fetchLinks({ commit }) {
      return this._vm.$http
        .get('/api/user/links')
        .then((response) => {
          commit('SET_LINKS', response.data);
        })
        .catch(() => {
          commit('SET_HAS_ERROR', true, { root: true });
        });
    },
    // fetchElectives({ commit }) {
    //   return this._vm.$http
    //     .get('/api/user/electivepd/')
    //     .then((response) => {
    //       commit('SET_ELECTIVES', response.data);
    //     })
    //     .catch(() => {
    //       commit('SET_HAS_ERROR', true, { root: true });
    //     });
    // },
  },
};

export default moduleDocuments;
