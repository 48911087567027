import 'babel-polyfill';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import vuetify from './plugins/vuetify';
import { IeBlocker } from '@nswdoe/doe-ui-core';
import { OAuth } from '@nswdoe/doe-ui-core';
import { vuegtm } from './plugins/vuegtm';
import moment from 'moment';
import './scss/styles.css';

// Enhance the event system on Vue.prototype
import {
  useEventHub,
  useUpwardBroadcast,
  useDownwardBroadcast,
} from './hooks/events';
useEventHub(Vue);
useUpwardBroadcast(Vue);
useDownwardBroadcast(Vue);

import VueCompositionAPI from '@vue/composition-api';
Vue.use(VueCompositionAPI);

/**
 * The mixin for implementing dynamic <title> in SPA according to different 'view/page' the user views.
 * It's to support better accessibility.
 * The mixin is from the official doc https://ssr.vuejs.org/guide/head.html
 */
import titleMixin from './mixins/titleMixin';
Vue.mixin(titleMixin);

let renderComponent, renderProps;

if (IeBlocker.isIe()) {
  // render IeBlocker
  renderComponent = IeBlocker;
  renderProps = {
    props: {
      block: true,
      appName: 'qplus',
    },
  };
} else {
  // render App, config the App root instance (oAuth plugin, etc.
  renderComponent = App;
  renderProps = {};

  Vue.config.productionTip = false;
}

Vue.filter('dateFormat', function (value) {
  if (!value) return '';
  return moment(value).format('DD/MM/YYYY');
});

const authOptions = {
  router: router,
  pkce: true,
  authoriseUri: process.env.VUE_APP_AUTH_URL,
  tokenUri: process.env.VUE_APP_TOKEN_URL,
  forceProdMode: true,
  logoutUri: process.env.VUE_APP_LOGOUT_URL,
  secureApp: true,

  params: {
    client_id: process.env.VUE_APP_CLIENT_ID,
    redirect_uri: `${window.location.origin}/login/callback`,
    scope: 'User.Read profile openid email',
  },
};

Vue.use(OAuth, authOptions);
vuegtm(Vue);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(renderComponent, renderProps),
}).$mount('#app');
