<template>
  <v-app :class="{ mobile: $vuetify.breakpoint.xsOnly }">
    <NavigationDrawer class="d-print-none" :items="navDrawerItems" />

    <CustomNotifications
      v-if="isNesaUser"
      style="top: 64px"
      :notificationData="drawerNotifications"
      @input="getNotifications"
      @onDelete="deleteNotificationById"
    />

    <AppBar
      color="primary"
      class="d-print-none"
      appName="my-nsw-gov"
      title="Q+"
      :showFullScreen="false"
      :showNavIcon="true"
      :showNotifications="true"
      :newNotifications="drawerNotifications.length > 0 && isNesaUser"
    >
      <template v-slot:appIcon>
        <AppIcon
          v-show="!$vuetify.breakpoint.xs"
          appName="my-nsw-gov"
          :transparent="true"
          size="50px"
          class="mt-2"
        />
      </template>

      <template v-slot:dataSwitcher />

      <!--      Commenting out essentials drawer as our qplus user token is missing attribute locations/detattribute12 which is required for bookmark api-->
      <!--      <template v-slot:myEssentials>-->
      <!--        <MyEssentials-->
      <!--          :essentialsData="$store.state.moduleEssentials.apps"-->
      <!--          title="Recently used"-->
      <!--          essentialText="Go to My Essentials"-->
      <!--          essentialUrl="https://google.com"-->
      <!--          @input="getEssentials"-->
      <!--        >-->
      <!--          &lt;!&ndash;          Note: you can also pass in a slot for your customized header as shown below-->
      <!--                    <template v-slot:header>-->
      <!--                      <ul>-->
      <!--                        <li>paragraph 1</li>-->
      <!--                        <li>paragraph 2</li>-->
      <!--                      </ul>-->
      <!--                    </template>&ndash;&gt;-->
      <!--        </MyEssentials>-->
      <!--      </template>-->

      <template v-slot:profile>
        <Profile
          :givenName="userFirstName"
          :surname="userLastName"
          :updateProfile="false"
          :changePassword="false"
          :updateSecretQuestion="false"
          :portalSettings="false"
          :logout="true"
          :logoutHandler="logout"
          tabindex="0"
        />
      </template>
    </AppBar>
    <v-main v-if="$OAuth.data && $OAuth.data.profile">
      <v-fade-transition mode="out-in" v-if="isNesaUser">
        <router-view />
      </v-fade-transition>
      <v-container v-else>
        Q+ is an accreditation and professional planning tool for teachers with
        an active NESA ID. If you are currently undertaking accreditation, and
        having trouble accessing Q+, please follow this link to the
        <a
          href="https://education.nsw.gov.au/inside-the-department/human-resources/performance/school-teachers/accreditation/qplus"
          >Accreditation Q+ web page</a
        >
        for more information and support.
        <p>
          <b
            >To access Q+ you need to update your location in eTAMs to a DoE
            school.</b
          >
        </p>
      </v-container>
    </v-main>

    <!-- App-wide component -->
    <BlockUI v-if="$store.state.isLoading" />

    <Alert
      type="warning"
      html-text="An error has occurred while retrieving your data<br/><span style='font-weight: normal'>Q+ is an accreditation and professional planning tool for teachers with an active NESA ID. If you are currently undertaking accreditation, and having trouble accessing Q+, please follow this link to the <a href='https://education.nsw.gov.au/inside-the-department/human-resources/performance/school-teachers/accreditation/qplus' target='_blank'>Accreditation Q+ web page</a> for more information and support.</span><br/><b>To access Q+ you need to update your location in eTAMs to a DoE school.</b>"
      actionText="Close"
      :showAlert="$store.state.hasError && !sessionExpired"
      :actionHandler="clearError"
    />

    <Alert
      type="info"
      text="Session expired"
      subtext="Refresh the page to log in again"
      :showAlert="sessionExpired"
    />
  </v-app>
</template>

<script>
import {
  AppBar,
  NavigationDrawer,
  Profile,
  AppIcon,
  BlockUI,
  Alert,
} from '@nswdoe/doe-ui-core';
import CustomNotifications from './components/CustomNotifications';
import { mapGetters } from 'vuex';
import Vue from 'vue';
import axios from 'axios';
import _ from 'lodash';
import { gtmDeleteNotification } from './plugins/gtm-utils';

Vue.prototype.$http = axios.create();

export default {
  name: 'App',
  components: {
    BlockUI,
    AppBar,
    NavigationDrawer,
    Profile,
    AppIcon,
    CustomNotifications,
    Alert,
  },
  data() {
    return {
      // Sample data for NavigationDrawer
      navDrawerItems: [
        {
          title: 'My Status',
          icon: 'home',
          iconOutlined: true,
          route: '/',
          exact: '/',
        },
        {
          title: 'My Details',
          icon: 'person_pin',
          iconOutlined: true,
          route: '/full-details',
        },
        {
          title: 'Workspace',
          icon: 'playlist_add_check',
          iconOutlined: false,
          route: '/tasks',
        },
        {
          title: 'Resources',
          icon: 'apps',
          iconOutlined: false,
          route: '/resources',
        },
        {
          title: 'My Documents & Links',
          icon: 'file_copy',
          iconOutlined: true,
          route: '/documents-and-links',
        },
        {
          title: 'PDP',
          icon: 'person_pin',
          iconOutlined: true,
          items: [
            {
              id: 'nav-pdp-my-pdp',
              title: 'My PDP',
              clickHandler: () =>
                (window.location.href = `${process.env.VUE_APP_PDP_DOMAIN}/my-pdp`),
            },
            {
              id: 'nav-pdp-management',
              title: 'Management',
              clickHandler: () =>
                (window.location.href = `${process.env.VUE_APP_PDP_DOMAIN}/management`),
            },
            {
              id: 'nav-pdp-guide',
              title: 'PDP Guide',
              clickHandler: () =>
                (window.location.href = `${process.env.VUE_APP_PDP_DOMAIN}/pdp-guide`),
            },
          ],
        },
        {
          title: 'Feedback',
          icon: 'storefront',
          iconOutlined: true,
          route: '/stay-connected',
        },
        {
          title: 'Support',
          icon: 'help_outline',
          iconOutlined: true,
          route: '/support-feedback',
        },
      ],
      sessionExpired: false,
    };
  },
  computed: {
    ...mapGetters({
      userProfile: 'moduleUserProfile/userProfile',
      drawerNotifications: 'moduleNotifications/drawerNotifications',
    }),
    isNesaUser() {
      return (
        this.$OAuth.data.profile &&
        !_.isNil(this.$OAuth.data.profile.DETLHRServiceNumber)
      );
    },
    userFirstName() {
      if (this.userProfile.FirstName) {
        return this.userProfile.FirstName;
      } else {
        return this.$OAuth.data?.profile?.name;
      }
    },
    userLastName() {
      if (this.userProfile.LastName) {
        return this.userProfile.LastName;
      } else {
        return '';
      }
    },
  },
  created() {
    this.$http.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
    this.$http.interceptors.request.use(
      (config) => {
        config.headers.common['Authorization'] =
          'Bearer ' + this.$OAuth.data.accessToken;
        config.headers.common['id_token'] = this.$OAuth.data.idToken;
        return config;
      },
      (error) => {
        return Promise.reject(error);
      }
    );

    this.$http.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 403) {
          this.sessionExpired = true;
        }
        return Promise.reject(error);
      }
    );
  },
  methods: {
    clearError() {
      this.$store.commit('SET_HAS_ERROR', false);
    },
    logout() {
      this.$OAuth.logout();
    },
    getNotifications(open) {
      if (open) {
        this.$store.dispatch('moduleNotifications/fetchNotifications');
      }
    },
    deleteNotificationById(notification) {
      this.$store.dispatch(
        'moduleNotifications/deleteNotification',
        notification.requestId
      );
      gtmDeleteNotification(this.$gtm, notification);
    },
    getEssentials(open) {
      if (open) {
        this.$store.dispatch('moduleEssentials/fetchMyEssentialsApps');
      }
    },
  },
};
</script>

<style lang="scss">
.container {
  padding: 12px 30px;

  .v-list--dense .v-list-item {
    min-height: 30px;
  }

  .container {
    padding: 0;
  }
}

// Fix banner bug
.banner.row {
  margin-top: 0;
  margin-bottom: 0;
}

.mobile {
  .container {
    padding: 12px 4px;
  }
}

@media print {
  .v-main {
    padding: 0 !important;
  }
}
</style>
